@media (min-width: 576px) {
  .progress-wrap.active-progress {
    opacity: 1;
  }
}
.progress-wrap {
  position: fixed;
  right: 1.5rem;
  bottom: 1.5rem;
  width: 2.3rem;
  height: 2.3rem;
  cursor: pointer;
  display: block;
  border-radius: 100%;
  box-shadow: inset 0 0 0 0.1rem rgba(128, 130, 134, 0.25);
  z-index: 1010;
  opacity: 0;
  visibility: hidden;
  transform: translateY(0.75rem);
  transition:
    all 200ms linear,
    margin-right 0ms;
}
@media (prefers-reduced-motion: reduce) {
  .progress-wrap {
    transition: none;
  }
}
.progress-wrap.active-progress {
  visibility: visible;
  transform: translateY(0);
}
.progress-wrap:after {
  position: absolute;
  font-family: "Unicons";
  content: "\e951";
  text-align: center;
  line-height: 2.3rem;
  font-size: 1.2rem;
  color: #001234;
  left: 0;
  top: 0;
  height: 2.3rem;
  width: 2.3rem;
  cursor: pointer;
  display: block;
  z-index: 1;
  transition: all 200ms linear;
}
@media (prefers-reduced-motion: reduce) {
  .progress-wrap:after {
    transition: none;
  }
}
.progress-wrap svg path {
  fill: none;
}
.progress-wrap svg.progress-circle path {
  stroke: #001234;
  stroke-width: 4;
  box-sizing: border-box;
  transition: all 200ms linear;
}
@media (prefers-reduced-motion: reduce) {
  .progress-wrap svg.progress-circle path {
    transition: none;
  }
}
@media (min-width: 576px) {
  .progress-wrap.active-progress {
    opacity: 1;
  }
}

@-webkit-keyframes slideInDown {
  0% {
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes slideInDown {
  0% {
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@-webkit-keyframes zoomIn {
  0% {
    opacity: 0;
    transform: scale(0.8);
    -webkit-transform: scale(0.8);
  }
  100% {
    opacity: 1;
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}
@keyframes zoomIn {
  0% {
    opacity: 0;
    transform: scale(0.8);
    -webkit-transform: scale(0.8);
  }
  100% {
    opacity: 1;
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}

.animate__slideInDown {
  -webkit-animation-name: slideInDown;
  animation-name: slideInDown;
  animation-timing-function: ease;
  animation-direction: normal;
  animation-fill-mode: both;
}

.animate__zoomIn {
  -webkit-animation-name: zoomIn;
  animation-name: zoomIn;
  animation-timing-function: ease;
  animation-direction: normal;
  animation-fill-mode: both;
}

.sticky_header {
  box-shadow: 0rem 0rem 1.25rem rgba(30, 34, 40, 0.06);
  background: rgba(255, 255, 255, 0.97);
}

.different_sticky {
  position: sticky;
  z-index: 1000;
}

@media (min-width: 850px) {
  .different_sticky {
    top: -55px;
  }
}
@media (max-width: 849px) {
  .different_sticky {
    top: -125px;
  }
}
@media (max-width: 850px) {
  .submenu_container {
    flex-direction: column;
  }
}
.general_header_sticky {
  position: sticky;
  z-index: 1000;
  top: 0;
}
.transparency_class {
  background: rgba(255, 255, 255, 0.2) !important;
}

.transform_header {
  background: rgba(255, 255, 255, 0.85);
}
.transform_header > div > div > div > ul > li > a {
  color: #343f52 !important;
}
.transform_header > div > div > div > ul > li > a:hover {
  color: #7f6a00 !important;
}

.transform_header > div > div > ul > li > a > button > div > svg {
  stroke: #343f52;
}

.transform_header > div > div > ul > li > a > button:hover > div > svg {
  stroke: #7f6a00;
}
.transform_header > div > div > ul > li:first-child > a {
  color: #ecd785 !important;
  background-color: #001234 !important;
  border-color: #001234 !important;
}
.transform_header > div > div > ul > li:first-child > a:hover {
  color: #ffffff !important;
  background-color: #001234 !important;
  border-color: #001234 !important;
}

.transform_header > div > div > ul > li:last-child > button {
  color: #001234 !important;
}
.transform_header > div > div > div > ul > li > a > button > div > svg {
  stroke: #343f52 !important;
}
.transform_header > div > div > ul > li > a > svg,.transform_header > div > div > div > ul > li > a > svg  {
  fill: #343f52 !important;
}
.transform_header > div > div > ul > li:hover > a > svg,
.transform_header > div > div > div > ul > li:hover > a > svg {
  fill: #7f6a00 !important;
}
.transform_header > div > div > div > ul > li > a > button:hover > div > svg {
  stroke: #7f6a00 !important;
}
.basket-item > a > button:hover > div > svg {
  stroke: rgba(255, 255, 255, 0.7);
}

.navbar-clone > div > div > ul > li > a > button > div > svg {
  stroke: #343f52;
}

.right-dropdown{
  right:0 !important;
  left:auto !important;
  position: absolute !important;
  -webkit-box-shadow: 0px -4px 36px 0px rgba(0,0,0,0.75) !important;
  -moz-box-shadow: 0px -4px 36px 0px rgba(0,0,0,0.75) !important;
  box-shadow: 0px -4px 36px 0px rgba(0,0,0,0.75) !important;
}

@keyframes errorAnimation {
  0%{
    bottom: 0
  }
  100%{
    bottom: -24px
  }
}
@keyframes errorAccountAnimation {
  0%{
    opacity: 0;
    top:-50px;
  }
  100%{
    opacity: 1;
    top:-20px;
  }
}